import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, mobileLogo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/z1n6s0Q1U12JBeruNx0e/locations/0nBUuNbz0naRmB9PPMgD'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          bg="rgb(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          pv="5px"
        >
          <CFImage w="70%" src={logo} alt="Victoria Sushi Logo" />
          <CFView pv="8px">
            <LocationSelect />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="55px"
          bg="rgb(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={98}
          w="100%"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row center>
              <CFImage
                h="70px"
                mt="25px"
                ml="15px"
                src={logo}
                alt="Victoria Sushi Logo"
                zIndex={98}
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView zIndex={99} ml="15px" mt="25px">
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
