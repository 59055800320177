import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
  CFText,
} from 'components'
import { mobilePromotion, promotions, promoParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
        </CFView>
        <CFView
          color="rgba(0,0,0,0)"
          style={{ lineHeight: 0 }}
          maxWidth="300px"
        >
          <CFView>GRAND OPENING</CFView>
          <CFView>
            Come and experience the friendly and unforgettable Sushi On. We are
            offering 10% off on all items on the menu.
          </CFView>
          <CFView>SIGNATURE DISH</CFView>
          <CFView>
            Sushi On’s most popular choice! We are dedicated to making sure that
            our customers want to come back.
          </CFView>
          <CFView>ORDERING APP</CFView>
          <CFView>
            Order and pay conveniently online with our Sushi On App! Get instant
            notification when your order is done.
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1100px" pv="30px" pl="10px">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
        <Parallax
          bgImage={promoParallax}
          bgImageAlt="Port Coquitlam Sushi On PromoParallax"
          strength={200}
        >
          <div style={{ height: '400px' }} />
        </Parallax>
        <CFView
          color="rgba(0,0,0,0)"
          style={{ lineHeight: '2px' }}
          maxWidth="1100px"
        >
          <CFView>GRAND OPENING</CFView>
          <CFView>
            Come and experience the friendly and unforgettable Sushi On. We are
            offering 10% off on all items on the menu.
          </CFView>
          <CFView>SIGNATURE DISH</CFView>
          <CFView>
            Sushi On’s most popular choice! We are dedicated to making sure that
            our customers want to come back.
          </CFView>
          <CFView>ORDERING APP</CFView>
          <CFView>
            Order and pay conveniently online with our Sushi On App! Get instant
            notification when your order is done.
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
