import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Ogenki-Sushi/114572371922971">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi On Facebook"
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/ogenki-sushi-vancouver">
              <CFImage w="45px" pr="10px" src={yelp} alt="Sushi On Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/ogenki-sushi-riley-park-little-mountain-vancouver">
              <CFImage w="45px" pr="10px" src={zomato} alt="Sushi On Zomato" />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushionpoco/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Sushi On Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Ogenki-Sushi/114572371922971">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi On Facebook"
                hover
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/ogenki-sushi-vancouver">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Sushi On Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/ogenki-sushi-riley-park-little-mountain-vancouver">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi On Zomato"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushionpoco/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Sushi On Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
