import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-15px">
          <CFImage
            src={mobileAbout}
            w="95%"
            alt="Port Coquitlam Sushi On Intro"
          />
        </CFView>
        <CFView
          color="rgba(0,0,0,0)"
          style={{ lineHeight: 0 }}
          maxWidth="300px"
        >
          <CFView>
            Sushi On is Port Coquitlam’s hidden gem. We are dedicated to making
            sure that our customers want to come back. Our goal is to bring the
            highest-quality dining experience to our customers.
          </CFView>
          <CFView>
            We have 48 special rolls for you to explore. Try something new each
            time you visit. With mouthwatering appetizers and fresh draft beer,
            we offer a wide selection for you to choose from.
          </CFView>
          <CFView>
            We serve customers with the belief that a “sushi-night” should be
            more than just decent sushi. With friendly servers and a comfortable
            interior, we warmly welcome you to dine with us.
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView row justifyStart alignCenter m="0 auto" pt="30px">
          <CFImage src={about} w="1200px" alt="Port Coquitlam Sushi On Intro" />
        </CFView>
        {/* <CFView
          h="400px"
          column
          center
          m="0 auto"
          maxWidth="1100px"
          style={{ backgroundAttachment: 'fixed' }}
          image={`url(${aboutParallax}) center / 1100px auto no-repeat`}
        ></CFView> */}
        <CFView maxWidth="1100px" m="0 auto">
          <Parallax
            bgImage={aboutParallax}
            bgImageAlt="Port Coquitlam Sushi On aboutParallax"
            strength={300}
          >
            <div style={{ height: '400px' }} />
          </Parallax>
        </CFView>
        <CFView
          color="rgba(0,0,0,0)"
          style={{ lineHeight: '1px' }}
          maxWidth="1100px"
        >
          <CFView>
            Sushi On is Port Coquitlam’s hidden gem. We are dedicated to making
            sure that our customers want to come back. Our goal is to bring the
            highest-quality dining experience to our customers.
          </CFView>
          <CFView>
            We have 48 special rolls for you to explore. Try something new each
            time you visit. With mouthwatering appetizers and fresh draft beer,
            we offer a wide selection for you to choose from.
          </CFView>
          <CFView>
            We serve customers with the belief that a “sushi-night” should be
            more than just decent sushi. With friendly servers and a comfortable
            interior, we warmly welcome you to dine with us.
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
