import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  doordash,
  hero,
  heroText,
  logo,
  mobileHero,
  orderPickupButton,
  downloadAppButton,
  mobileHeroText,
  mobileOrderButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          justifyBetween
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
        >
          <Header />
          <CFView textCenter column justifyEnd alignCenter mb="15px">
            <CFView column center pt="10px">
              <OrderPickupButton />
              <a href="http://onelink.to/sushion">
                <CFView hover>
                  <CFImage
                    src={downloadAppButton}
                    maxWidth="280px"
                    alt="About"
                  />
                </CFView>
              </a>{' '}
              <a href="https://www.doordash.com/store/sushi-on-ginza-sushi-port-coquitlam-204892/en-US/?utm_campaign=gpa">
                <CFView hover>
                  <CFImage src={doordash} maxWidth="280px" alt="About" />
                </CFView>
              </a>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) bottom/ cover no-repeat`}
          boxShadow="0 2px 3px rgba(0,0,0,.6)"
          zIndex={90}
          relative
        >
          <Header />
          <CFView
            row
            justifyEnd
            alignCenter
            absolute
            w="100%"
            maxWidth="1200px"
            pv="5px"
            bw="1.5px"
            style={{
              bottom: '0',
              right: '50%',
              transform: 'translateX(50%)',
            }}
          >
            <CFView row center pb="30px">
              <OrderPickupButton />
              <a href="http://onelink.to/sushion">
                <CFView hover ml="15px">
                  <CFImage
                    src={downloadAppButton}
                    maxWidth="280px"
                    alt="About"
                  />
                </CFView>
              </a>
              <a href="https://www.doordash.com/store/sushi-on-ginza-sushi-port-coquitlam-204892/en-US/?utm_campaign=gpa">
                <CFView hover ml="15px">
                  <CFImage src={doordash} maxWidth="280px" alt="About" />
                </CFView>
              </a>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
